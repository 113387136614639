import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Autocomplete, Button, Chip, Icon, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import Loading from '@/components/utility/Loading';
import AutoCompleteInput from '@/components/utility/microcomponents/AutoCompleteInput';
import ButtonComponent from '@/components/utility/microcomponents/Button';
import { advertGenders, tikTokAgeRanges } from '@/constants/Advert';
import useTikTokLocationRecommendations from '@/hooks/tiktok/useTikTokLocationRecommendations';
import useTikTokRecommendedInterests from '@/hooks/tiktok/useTikTokRecommendedInterests';
import useTikTokSearchInterestOptions from '@/hooks/tiktok/useTikTokSearchInterestOptions';
import useTikTokSearchLocationOptions from '@/hooks/tiktok/useTikTokSearchLocationOptions';
import useTikTokSuggestedInterests from '@/hooks/tiktok/useTikTokSuggestedInterests';
import useTikTokTargetingSuggestions from '@/hooks/tiktok/useTikTokTargetingSuggestions';
import { useDebounce } from '@/hooks/useDebounce';
import { AdvertGoalModel } from '@/models/Adverts';
import { IdName, IdNameType } from '@/models/Generic';
import {
  TikTokAdsFormModel,
  TikTokAdsInterestModel,
  TikTokAdsInterestSuggestionModel,
  TikTokAdsLocationModel,
  TikTokAdsLocationSuggestionModel,
} from '@/models/TikTok';

import AudienceTargeting from '../../generic-ads-steps/AudienceTargeting';

const TikTokLocationComponent = ({
  goal,
  locationSuggestions,
  targetingIsLoading,
}: {
  goal: AdvertGoalModel | undefined;
  locationSuggestions?: TikTokAdsLocationSuggestionModel[];
  targetingIsLoading: boolean;
}) => {
  const { t } = useTranslation();

  const [locationSearchValue, setLocationSearchValue] = useState<string>('');
  const [autoCompleteKey, setAutoCompleteKey] = useState<string>(uuidv4());

  const { setValue, watch } = useFormContext<TikTokAdsFormModel>();
  const formAdvertiserId = watch('adAccount.advertiserId');
  const formLocations = watch('ad.targeting.locations');

  const { locationRecommendations, locationRecommendationsIsLoading } = useTikTokLocationRecommendations({
    advertiserId: formAdvertiserId,
    goal,
  });
  const { searchLocationOptions, searchLocationOptionsIsLoading, refetchSearchLocationOptions } =
    useTikTokSearchLocationOptions({
      advertiserId: formAdvertiserId,
      goal,
      search: locationSearchValue,
    });

  useEffect(() => {
    if (formLocations.length) return;
    if (!locationRecommendations) return;
    if (locationSuggestions && locationSuggestions.length > 0 && !targetingIsLoading) {
      setValue(
        'ad.targeting.locations',
        locationSuggestions.map((location) => ({ id: location.geo.geo_id, name: location.name }))
      );
    } else {
      setValue(
        'ad.targeting.locations',
        locationRecommendations.map((location) => ({ id: location.location_id, name: location.name }))
      );
    }
  }, [formLocations.length, locationRecommendations, locationSuggestions, setValue, targetingIsLoading]);

  useEffect(() => {
    if (locationSearchValue.length) {
      refetchSearchLocationOptions();
    }
  }, [locationSearchValue, refetchSearchLocationOptions]);

  const handleResetLocationAutocomplete = useCallback(async () => {
    setLocationSearchValue('');
    setAutoCompleteKey(uuidv4());
    await refetchSearchLocationOptions(undefined);
  }, [refetchSearchLocationOptions]);

  const handleUpdateLocation = useCallback(
    async (location: TikTokAdsLocationModel) => {
      setValue('ad.targeting.locations', [...formLocations, { id: location.location_id, name: location.name }]);
      await handleResetLocationAutocomplete();
    },
    [formLocations, handleResetLocationAutocomplete, setValue]
  );

  const handleDeleteLocation = useCallback(
    (location: IdName) => {
      setValue(
        'ad.targeting.locations',
        formLocations.filter((value) => value.id !== location.id)
      );
    },
    [formLocations, setValue]
  );

  if (targetingIsLoading) return <Loading size="small" />;

  return (
    <div className="card-inner p16">
      <div className="mt0 mb0 d-flex gap20">
        <img className="platform-icon mt-auto mb-auto" src="/images/icons/location.svg" alt="" />
        <p className="mt-auto mb-auto">{t('SOCIAL-ADS.SELECT-CUSTOM-LOCATION')}</p>
      </div>
      <div>
        <p className="text-left mt8 text-faded small">{t('SOCIAL-ADS.SELECT-CUSTOM-LOCATION-DESCRIPTION')}</p>
        <div className="mt16">
          <Autocomplete
            key={autoCompleteKey}
            options={searchLocationOptions}
            noOptionsText={<p className="text-white">No options available</p>}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={() => true}
            onBlur={handleResetLocationAutocomplete}
            onChange={(_, value) => {
              if (!value) return;
              handleUpdateLocation(value);
              setLocationSearchValue('');
            }}
            loading={searchLocationOptionsIsLoading}
            loadingText={<Loading size="small" />}
            renderOption={(props, option) => (
              <li {...props} key={option.location_id}>
                <div className="d-flex">
                  <div>
                    <p>{option.name}</p>
                    <p className="text-faded small mt-6">
                      {option.parents.map(
                        (item, index: number) => `${item}${index === option.parents.length - 1 ? '' : ', '}`
                      )}
                    </p>
                  </div>
                </div>
              </li>
            )}
            renderInput={(params) => (
              <AutoCompleteInput
                params={params}
                placeholder={t('SOCIAL-ADS.SEARCH-FOR-LOCATIONS')}
                onChange={(event) => setLocationSearchValue(event.target.value)}
              />
            )}
          />
          {locationRecommendationsIsLoading ? (
            <Loading size="small" />
          ) : (
            <div className={`d-flex gap8 flex-wrap ${formLocations.length > 0 ? 'mt16' : ''}`}>
              {formLocations.length > 0 &&
                formLocations.map((item) => (
                  <Chip
                    key={item.id}
                    label={item.name}
                    data-testid={`social-chip-${item.name}`}
                    deleteIcon={
                      <ButtonComponent isCustom className="p0">
                        <Icon className="fs-16" data-testid={`social-chip-${item.name}-delete`}>
                          close
                        </Icon>
                      </ButtonComponent>
                    }
                    onDelete={() => handleDeleteLocation(item)}
                  />
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const TikTokInterestsComponent = ({
  interestsSuggestions,
  targetingIsLoading,
}: {
  interestsSuggestions?: TikTokAdsInterestSuggestionModel[];
  targetingIsLoading: boolean;
}) => {
  const { t } = useTranslation();

  const [interestsSearchValueDebounced, setInterestsSearchValueDebounced] = useState<string>('');
  const [interestsSearchValue, setInterestsSearchValue] = useState<string>('');
  const [autoCompleteKey, setAutoCompleteKey] = useState<string>(uuidv4());
  const [suggestedInterestOptions, setSuggestedInterestsOptions] = useState<IdNameType[]>([]);

  const { setValue, watch } = useFormContext<TikTokAdsFormModel>();
  const formAdvertiserId = watch('adAccount.advertiserId');
  const formInterests = watch('ad.targeting.interests');

  const { recommendedInterests, recommendedInterestsIsLoading } = useTikTokRecommendedInterests({
    advertiserId: formAdvertiserId,
  });
  const { suggestedInterests, suggestedInterestsIsLoading, refetchSuggestedInterests } = useTikTokSuggestedInterests({
    advertiserId: formAdvertiserId,
    interests: formInterests,
  });
  const { searchInterestOptions, searchInterestOptionsIsLoading, refetchSearchInterestOptions } =
    useTikTokSearchInterestOptions({
      advertiserId: formAdvertiserId,
      search: interestsSearchValueDebounced,
    });

  useEffect(() => {
    if (formInterests.length) return;
    if (!recommendedInterests) return;
    if (interestsSuggestions && interestsSuggestions.length > 0) {
      setValue(
        'ad.targeting.interests',
        interestsSuggestions.map((item) => ({
          type: item.type,
          id: item.id,
          name: item.name,
        }))
      );
    } else {
      setValue('ad.targeting.interests', recommendedInterests);
    }
  }, [formInterests.length, interestsSuggestions, recommendedInterests, setValue]);

  useEffect(() => {
    if (suggestedInterests) {
      setSuggestedInterestsOptions(suggestedInterests);
    }
  }, [setSuggestedInterestsOptions, suggestedInterests]);

  useEffect(() => {
    if (interestsSearchValue.length) {
      refetchSearchInterestOptions();
    }
  }, [interestsSearchValue, refetchSearchInterestOptions]);

  const handleResetInterestAutocomplete = useCallback(async () => {
    setInterestsSearchValue('');
    setInterestsSearchValueDebounced('');
    setAutoCompleteKey(uuidv4());
    await refetchSearchInterestOptions(undefined);
  }, [refetchSearchInterestOptions]);

  const handleUpdateInterests = useCallback(
    async (interest: TikTokAdsInterestModel) => {
      const updatedInterests = [...formInterests, interest];
      setValue('ad.targeting.interests', updatedInterests);

      await refetchSuggestedInterests();
      await handleResetInterestAutocomplete();
    },
    [formInterests, handleResetInterestAutocomplete, refetchSuggestedInterests, setValue]
  );

  const handleDeleteInterest = useCallback(
    async (interest: TikTokAdsInterestModel) => {
      const updatedInterests = formInterests.filter((value) => value.id !== interest.id);
      setValue('ad.targeting.interests', updatedInterests);
      await refetchSuggestedInterests();
    },
    [formInterests, refetchSuggestedInterests, setValue]
  );

  useDebounce(
    () => {
      setInterestsSearchValueDebounced(interestsSearchValue);
    },
    300,
    [interestsSearchValue, setInterestsSearchValueDebounced]
  );

  if (targetingIsLoading) return <Loading size="small" />;

  return (
    <div className="card-inner p16 gap20 text-left">
      <Autocomplete
        key={autoCompleteKey}
        options={searchInterestOptions}
        noOptionsText={<p className="text-white">No options available</p>}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={() => true}
        onBlur={handleResetInterestAutocomplete}
        onChange={(_, value) => {
          if (!value) return;
          handleUpdateInterests(value);
        }}
        loading={searchInterestOptionsIsLoading}
        loadingText={<Loading size="small" />}
        renderOption={(props, option) => (
          <li {...props} key={option.id}>
            <div>
              <p>{option.name}</p>
            </div>
          </li>
        )}
        renderInput={(params) => (
          <AutoCompleteInput
            params={params}
            placeholder={t('SOCIAL-ADS.SEARCH-FOR-INTERESTS')}
            onChange={(event) => setInterestsSearchValue(event.target.value)}
          />
        )}
      />
      {recommendedInterestsIsLoading ? (
        <Loading size={'small'} />
      ) : (
        <div>
          <p className="fw-bold mt16">{t('SOCIAL-ADS.SELECTED-INTEREST-TARGETS')}</p>
          <div className="d-flex mt16 gap8 flex-wrap">
            {formInterests.length > 0 &&
              formInterests.map((item) => (
                <Chip
                  key={item.id}
                  label={item.name}
                  data-testid={`social-chip-${item.name}`}
                  deleteIcon={
                    <ButtonComponent isCustom className="p0">
                      <Icon className="fs-16" data-testid={`social-chip-${item.name}-delete`}>
                        close
                      </Icon>
                    </ButtonComponent>
                  }
                  onDelete={() => handleDeleteInterest(item)}
                />
              ))}
          </div>
        </div>
      )}
      {suggestedInterestsIsLoading || recommendedInterestsIsLoading ? (
        <Loading size={'small'} />
      ) : (
        <div>
          <p className="fw-bold mt16">{t('SOCIAL-ADS.MORE-SUGGESTED-INTEREST-TARGETS')}</p>
          <div className="d-flex mt16 gap8 flex-wrap">
            {suggestedInterestOptions &&
              suggestedInterestOptions.length > 0 &&
              suggestedInterestOptions.map((item) => (
                <Chip
                  className="unselected"
                  key={item.id}
                  label={item.name}
                  data-testid={`social-chip-${item.name}`}
                  onClick={() => handleUpdateInterests(item)}
                  deleteIcon={
                    <ButtonComponent isCustom className="p0">
                      <Icon className="fs-16" data-testid={`social-chip-${item.name}-delete`}>
                        close
                      </Icon>
                    </ButtonComponent>
                  }
                  onDelete={() =>
                    setSuggestedInterestsOptions(
                      suggestedInterestOptions.filter((suggestion) => suggestion.id !== item.id)
                    )
                  }
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

const TikTokAudienceTargeting = ({
  goal,
  canStep,
}: {
  goal: AdvertGoalModel | undefined;
  canStep: (value: boolean) => void;
}) => {
  const { t } = useTranslation();

  const { setValue, watch } = useFormContext<TikTokAdsFormModel>();
  const formGender = watch('ad.targeting.gender');
  const formAgeGroups = watch('ad.targeting.ageGroups');
  const formLocations = watch('ad.targeting.locations');
  const formInterests = watch('ad.targeting.interests');
  const advertiserId = watch('adAccount.advertiserId');

  const chosenGender = useMemo(() => {
    const gender = advertGenders.find((item) => item.value === formGender);
    if (gender) return gender;

    return advertGenders[0];
  }, [formGender]);

  const { targetingSuggestions, targetingSuggestionsIsLoading } = useTikTokTargetingSuggestions({
    advertiserId: advertiserId,
    goal: goal?.value,
  });

  useEffect(() => {
    canStep(!!(formGender && formAgeGroups.length > 0 && formLocations.length > 0 && formInterests.length > 0));
  }, [canStep, formAgeGroups, formGender, formInterests, formLocations]);

  return (
    <AudienceTargeting
      renderGenderComponent={() => (
        <ToggleButtonGroup value={chosenGender.value} className="gender-toggle-group mt16">
          {advertGenders.map((item) => (
            <ToggleButton
              key={item.value}
              value={item.value}
              onClick={() => setValue('ad.targeting.gender', item.value)}
            >
              <div className="d-flex">
                <img src={`/images/icons/gender-${item.icon}.svg`} alt="" />
                <p className="small pt0">{t(item.viewValue)}</p>
              </div>
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      )}
      renderAgeRangeComponent={() => (
        <div className="d-flex gap8 flex-wrap mt20">
          {tikTokAgeRanges.map((item) => (
            <Button
              key={item.value}
              className={`age-range-button ${formAgeGroups.includes(item.value) ? 'selected' : ''}`}
              onClick={() => {
                if (formAgeGroups.includes(item.value)) {
                  setValue(
                    'ad.targeting.ageGroups',
                    formAgeGroups.filter((ageGroup) => ageGroup !== item.value)
                  );
                } else {
                  setValue('ad.targeting.ageGroups', [...formAgeGroups, item.value]);
                }
              }}
            >
              {item.viewValue}
            </Button>
          ))}
        </div>
      )}
      renderLocationTargetingComponent={() => (
        <TikTokLocationComponent
          goal={goal}
          locationSuggestions={targetingSuggestions?.locations}
          targetingIsLoading={targetingSuggestionsIsLoading}
        />
      )}
      renderInterestsComponent={() => (
        <TikTokInterestsComponent
          interestsSuggestions={targetingSuggestions?.interests}
          targetingIsLoading={targetingSuggestionsIsLoading}
        />
      )}
    />
  );
};

export default TikTokAudienceTargeting;
