import React, { Dispatch, SetStateAction, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Icon } from '@mui/material';

import Loading from '@/components/utility/Loading';
import ButtonComponent from '@/components/utility/microcomponents/Button';
import TikTokPostTemplate from '@/components/utility/social-media-templates/TikTokPostTemplate';
import useTikTokUserVideos from '@/hooks/tiktok/useTikTokUserVideos';
import { TikTokAdsFormModel, TikTokAdsVideoModel } from '@/models/TikTok';
import { isValidUrl } from '@/utility/validations';

import DesignYourAds from '../../generic-ads-steps/DesignYourAds';

const TikTokDesignYourAds = ({
  setSelectedTikTokVideo,
  canStep,
}: {
  setSelectedTikTokVideo: Dispatch<SetStateAction<TikTokAdsVideoModel | undefined>>;
  canStep: (value: boolean) => void;
}) => {
  const { t } = useTranslation();

  const { setValue, watch, register } = useFormContext<TikTokAdsFormModel>();
  const formAdvertiserId = watch('adAccount.advertiserId');
  const formIdentityId = watch('adAccount.identityId');
  const formLink = watch('ad.link');
  const formTikTokItemId = watch('ad.tikTokItemId');

  const { userVideos, userVideosIsLoading } = useTikTokUserVideos({
    advertiserId: formAdvertiserId,
    identityId: formIdentityId,
  });

  const isValidLink = useMemo(() => formLink && !!isValidUrl(formLink), [formLink]);

  useEffect(() => {
    canStep(!!(formLink && formTikTokItemId && isValidLink));
  }, [canStep, formLink, formTikTokItemId, isValidLink]);

  const handleUpdateSelectedTikTokVideo = (video: TikTokAdsVideoModel) => {
    setSelectedTikTokVideo(video);
    setValue('ad.tikTokItemId', video.item_id);
  };

  return (
    <DesignYourAds>
      <div className="d-flex w100p gap16 flex-wrap">
        <div className="flex-w50p w100p-lg-down hide-lg-up text-left">
          <h3>{t('SOCIAL-ADS.ADD-YOUR-LINK')}</h3>
          <p className="text-faded mt8">{t('SOCIAL-ADS.ADD-YOUR-LINK-DESCRIPTION')}</p>
          <input
            className="mt20"
            type="text"
            placeholder="Paste your link here"
            onChange={(event) => setValue('ad.link', event.target.value)}
          />
          <div className="d-flex form-divider mt20 mb10">
            <div className="line" />
          </div>
        </div>

        <div className="flex-w50p w100p-lg-down d-flex gap16 flex-wrap">
          <div className="text-left">
            <h3>{t('SOCIAL-ADS.CHOOSE-YOUR-TIKTOK')}</h3>
            <p className="text-faded mt8">{t('SOCIAL-ADS.CHOOSE-YOUR-TIKTOK-DESCRIPTION')}</p>
          </div>
          {userVideosIsLoading ? (
            <Loading size="small" />
          ) : (
            userVideos?.video_list &&
            userVideos?.video_list.length > 0 &&
            userVideos?.video_list?.map((video) => (
              <ButtonComponent
                isCustom
                key={video.item_id}
                className={`tiktok-grid-post pos-rel ${formTikTokItemId === video.item_id ? 'selected' : ''}`}
                onClick={() => handleUpdateSelectedTikTokVideo(video)}
              >
                <TikTokPostTemplate postDetails={video} />
                {formTikTokItemId === video.item_id && <Icon className="text-blue selected-icon">check_circle</Icon>}
              </ButtonComponent>
            ))
          )}
        </div>
        <div className="flex-w50p w100p-lg-down hide-lg-down text-left">
          <h3>{t('SOCIAL-ADS.ADD-YOUR-LINK')}</h3>
          <p className="text-faded mt8">{t('SOCIAL-ADS.ADD-YOUR-LINK-DESCRIPTION')}</p>
          <input
            {...register('ad.link')}
            className={`mt20 ${formLink && !isValidLink && 'error-outline'}`}
            type="text"
            placeholder="Paste your link here"
          />
        </div>
      </div>
    </DesignYourAds>
  );
};

export default TikTokDesignYourAds;
