import React, { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Icon, MenuItem, Select } from '@mui/material';

import TikTokLoginButton from '@/components/utility/buttons/TikTokLoginButton';
import Loading from '@/components/utility/Loading';
import ButtonComponent from '@/components/utility/microcomponents/Button';
import useAccountContext from '@/hooks/context/useAccountContext';
import useTikTokAdvertisingId from '@/hooks/tiktok/useTikTokAdvertisingId';
import useTikTokIdentity from '@/hooks/tiktok/useTikTokIdentity';
import { AdvertCurrencyModel, AdvertGoalModel } from '@/models/Adverts';
import { TikTokAdsFormModel, TikTokAdvertisedIdDataModel, TikTokIdentityDataModel } from '@/models/TikTok';

const TikTokChooseYourPlatform = ({
  goal,
  outputCurrency,
  canStep,
  platforms,
}: {
  goal: AdvertGoalModel | undefined;
  outputCurrency: (currency: AdvertCurrencyModel) => void;
  canStep: (value: boolean) => void;
  platforms: { logo: string; name: string }[];
}) => {
  const { t } = useTranslation();

  const { account } = useAccountContext();

  const [tikTokAdAccounts, setTikTokAdAccounts] = useState<TikTokAdvertisedIdDataModel[]>([]);
  const [tikTokIdentities, setTikTokIdentities] = useState<TikTokIdentityDataModel[]>([]);
  const [hasTikTokAccount, setHasTikTokAccount] = useState<boolean>();

  const { setValue, watch } = useFormContext<TikTokAdsFormModel>();
  const formPlatforms = watch('ad.platforms');
  const formAdvertiserId = watch('adAccount.advertiserId');
  const formIdentityId = watch('adAccount.identityId');

  const { tiktokAdvertisingId, tiktokAdvertisingIdIsLoading, tiktokAdvertisingIdError } = useTikTokAdvertisingId();
  const { tiktokIdentity, tiktokIdentityIsLoading, refetchTiktokIdentity } = useTikTokIdentity({
    advertiserId: formAdvertiserId,
  });

  useEffect(() => {
    if (!tiktokAdvertisingIdError) return;
    setHasTikTokAccount(false);
  }, [tiktokAdvertisingIdError]);

  const setPlatformsFromGoal = useCallback(() => {
    if (!goal) return;
    switch (goal.value) {
      case 'tiktok-followers': {
        setValue(
          'ad.platforms',
          platforms.filter((item) => item.name.toLowerCase() === 'tiktok').map((item) => item.name)
        );
        break;
      }
    }
  }, [goal, platforms, setValue]);

  useEffect(() => {
    setPlatformsFromGoal();
  }, [setPlatformsFromGoal]);

  useEffect(() => {
    if (!tiktokAdvertisingId || !formPlatforms.includes('TikTok')) return;

    setTikTokAdAccounts(tiktokAdvertisingId.list);

    if (!formAdvertiserId) {
      setValue('adAccount.advertiserId', tiktokAdvertisingId.list[0]?.advertiser_id);
      setValue('priceLocale', tiktokAdvertisingId.list[0]?.currency.code);
      outputCurrency(tiktokAdvertisingId.list[0]?.currency);
    }
  }, [formAdvertiserId, formPlatforms, outputCurrency, setValue, tikTokAdAccounts, tiktokAdvertisingId]);

  useEffect(() => {
    if (!tiktokIdentity) return;

    setTikTokIdentities(tiktokIdentity.identity_list);
    if (tiktokIdentity.identity_list.length > 0 && !formIdentityId) {
      setValue('adAccount.identityId', tiktokIdentity.identity_list[0].identity_id);
    }
  }, [formIdentityId, setValue, tiktokIdentity]);

  const resetAccounts = useCallback(() => {
    setValue('adAccount.advertiserId', undefined);
    setValue('adAccount.identityId', undefined);
    setTikTokAdAccounts([]);
    setTikTokIdentities([]);
  }, [setValue]);

  useEffect(() => {
    if (!account) return;

    if (account.accessTokens?.filter((platform) => platform.platform === 'tiktok').length) {
      setHasTikTokAccount(true);
    } else {
      setHasTikTokAccount(false);
    }
  }, [account]);

  const handleSelectedTikTokAdAccount = (advertiserId: string) => {
    setValue('adAccount.advertiserId', advertiserId);
    refetchTiktokIdentity();

    const currency = tikTokAdAccounts.find((item) => item?.advertiser_id === advertiserId)?.currency;
    if (!currency) return;

    setValue('priceLocale', currency.code);
    outputCurrency(currency);
  };

  const handleSelectedTikTokIdentity = (identityId: string) => {
    setValue('adAccount.identityId', identityId);
  };

  useEffect(() => {
    canStep(!!(formAdvertiserId && formIdentityId && formPlatforms.length > 0 && !tiktokIdentityIsLoading));
  }, [formPlatforms, formAdvertiserId, formIdentityId, canStep, tiktokIdentityIsLoading]);

  return (
    <>
      <h3>{t('SOCIAL-ADS.CHOOSE-YOUR-PLATFORM')}</h3>
      <p className="text-faded mb48 mt16 mb20-lg-down">{t('SOCIAL-ADS.CHOOSE-YOUR-PLATFORM-DESCRIPTION')}</p>
      <div className="card">
        <div className="d-flex gap20 flex-wrap">
          <div className="flex-w40p w100p-lg-down text-left">
            <h3>{t('SOCIAL-ADS.CHOOSE-YOUR-PLATFORM')}</h3>
            <p className="text-faded mt8">{t('SOCIAL-ADS.CHOOSE-YOUR-PLATFORM-DESCRIPTION-2')}</p>
          </div>
          <div className="flex-w60p w100p-lg-down">
            {platforms.map((item, index) => (
              <ButtonComponent
                isCustom
                key={item.name}
                className={`card-inner d-flex gap20 cursor-pointer br16 p16 w100p ${
                  index === 0 ? 'mb8' : ''
                } ${formPlatforms.includes(item.name) ? 'chosen-goal' : ''}`}
                onClick={() => {
                  if (formPlatforms.includes(item.name)) {
                    setValue(
                      'ad.platforms',
                      formPlatforms.filter((platform) => platform !== item.name)
                    );
                    resetAccounts();
                  } else {
                    setValue('ad.platforms', [...formPlatforms, item.name]);
                  }
                }}
              >
                <div className="mt-auto mb-auto">
                  <img className="platform-icon" src={`/images/logos/${item?.logo}-logo-white.svg`} alt="" />
                </div>
                <div className="mt-auto mb-auto">
                  <p>{item?.name}</p>
                </div>
                <div className="mt-auto mb-auto ml-auto">
                  {!formPlatforms.includes(item.name) ? (
                    <Icon className="material-symbols-outlined text-faded">circle</Icon>
                  ) : (
                    <Icon className="text-white">check_circle</Icon>
                  )}
                </div>
              </ButtonComponent>
            ))}
          </div>
        </div>
        <div className="d-flex form-divider mt10 mb10">
          <div className="line"></div>
        </div>
        <div className="d-flex gap20 mt20 flex-wrap">
          <div className="flex-w40p w100p-lg-down text-left">
            <h3>{t('SOCIAL-ADS.CHOOSE-YOUR-ACCOUNT')}</h3>
            <p className="text-faded mt8">{t('SOCIAL-ADS.CHOOSE-YOUR-ACCOUNT-DESCRIPTION')}</p>
          </div>
          {formPlatforms.includes('TikTok') && (
            <div className="flex-w60p w100p-lg-down">
              {!hasTikTokAccount && (
                <div className="text-left">
                  <TikTokLoginButton />
                </div>
              )}
              {hasTikTokAccount && (
                <div className="card-inner text-left p16">
                  <p className="fw-bold">{t('SOCIAL-ADS.SELECT-TIKTOK-AD-ACCOUNT')}</p>
                  <p className="text-faded small">{t('SOCIAL-ADS.SELECT-TIKTOK-AD-ACCOUNT-DESCRIPTION')}</p>
                  {tiktokAdvertisingIdIsLoading ? (
                    <Loading size="small" />
                  ) : (
                    <>
                      {tikTokAdAccounts && tikTokAdAccounts?.length > 1 && (
                        <div className="mt20">
                          <Select
                            className="meta-select"
                            value={formAdvertiserId}
                            defaultValue={tikTokAdAccounts[0]?.advertiser_id}
                            onChange={(event) => handleSelectedTikTokAdAccount(event.target.value)}
                          >
                            {tikTokAdAccounts.map((item, index: number) => (
                              <MenuItem key={index} value={item?.advertiser_id}>
                                <div>
                                  <p className="fw-bold small pt0">{item?.name}</p>
                                  <p className="text-faded small pt0">
                                    {t('COMMON.ACCOUNT-ID')}
                                    {item?.advertiser_id}
                                  </p>
                                </div>
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      )}
                      {tikTokAdAccounts && tikTokAdAccounts?.length === 1 && (
                        <div className="mt20">
                          <p className="fw-bold small pt0">{tikTokAdAccounts[0]?.name}</p>
                          <p className="text-faded small pt0">
                            {t('COMMON.ACCOUNT-ID')}
                            {tikTokAdAccounts[0]?.advertiser_id}
                          </p>
                        </div>
                      )}
                      {tikTokAdAccounts && tikTokAdAccounts?.length === 0 && (
                        <div className="mt20 gap8">
                          <p className="fw-bold mt-auto mb-auto pt0">No TikTok ad account found</p>
                          <div className="text-left mt8">
                            <TikTokLoginButton />
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}

              {hasTikTokAccount && (
                <div className="card-inner text-left p16 mt8">
                  <p className="fw-bold">{t('SOCIAL-ADS.CHOOSE-TIKTOK-PAGE')}</p>
                  <p className="text-faded small">{t('SOCIAL-ADS.CHOOSE-TIKTOK-PAGE-DESCRIPTION')}</p>
                  {tiktokIdentityIsLoading || tiktokAdvertisingIdIsLoading ? (
                    <Loading size="small" />
                  ) : (
                    <>
                      {tikTokIdentities && tikTokIdentities?.length > 1 && (
                        <div className="mt20">
                          <Select
                            className="meta-select"
                            value={formIdentityId}
                            defaultValue={tikTokIdentities[0]?.identity_id}
                            onChange={(event) => handleSelectedTikTokIdentity(event.target.value)}
                          >
                            {tikTokIdentities?.map((item, index: number) => (
                              <MenuItem key={index} value={item?.identity_id}>
                                <div className="d-flex gap8">
                                  <div>
                                    <img
                                      className="social-media-profile-pics mt10"
                                      src={tikTokIdentities[0]?.profile_image}
                                      alt=""
                                    />
                                  </div>
                                  <p className="small fw-bold mt-auto mb-auto pt0">
                                    {tikTokIdentities[0]?.display_name}
                                  </p>
                                </div>
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      )}
                      {tikTokIdentities && tikTokIdentities?.length === 1 && (
                        <div className="mt20 d-flex gap8">
                          <div>
                            <img
                              className="social-media-profile-pics"
                              src={tikTokIdentities[0]?.profile_image || ''}
                              alt=""
                            />
                          </div>
                          <p className="small fw-bold mt-auto mb-auto pt0">{tikTokIdentities[0]?.display_name}</p>
                        </div>
                      )}
                      {tikTokIdentities && tikTokIdentities?.length === 0 && (
                        <div className="mt20 d-flex gap8">
                          <p className="fw-bold mt-auto mb-auto pt0">
                            No TikTok pages found connected with this ad account
                          </p>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TikTokChooseYourPlatform;
